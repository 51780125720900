<template>
    <div class="relative block w-full">
        <TooltipLabel v-if="!hiddenLabel" :for="id" :label="label" :important="important" :useTooltip="useTooltip"
                      :tooltipContent="tooltipContent"/>
        <div class="relative w-full flex p-3 rounded border border-zinc-200 bg-neutral-100 space-x-1">
            <div v-for="(item, index) in fileList" :key="index" class="m-1.5 rounded w-16 h-16 overflow-hidden bg-zinc-100">
                <img :src="item === '' ? '' : path+item" />
            </div>
        </div>
    </div>
</template>

<script setup>
import TooltipLabel from "@/components/input/TooltipLabel.vue";

import { defineProps } from "vue";

const path = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com';

defineProps({
    hiddenLabel   : Boolean,
    id            : String,
    label         : String,
    important     : Boolean,
    useTooltip    : Boolean,
    tooltipContent: String,
    fileList      : Array
});
</script>

<style scoped>

</style>