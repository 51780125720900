<template>
    <ModalBox hiddenPrev title="로그인">
        <div class="space-y-4">
            <LabelInput type="text" id="id" name="id" :value="obj['id']" label="아이디" placeholder="아이디를 입력하세요." @keyup.enter="getEnter" @input="getInputValSet"/>
            <LabelInput type="password" id="pw" name="pw" :value="obj['pw']" label="비밀번호" placeholder="비밀번호를 입력하세요." @keyup.enter="getEnter" @input="getInputValSet"/>
        </div>
        <FullButton buttonText="로그인" greyButton @click="getLogin"/>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import FullButton from "@/components/button/FullButton.vue";
import axios from "axios";
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
const router = useRouter();

const obj = ref({
    id : '',
    pw : ''
});

const getEnter = () => {
    const id = event.target.id;

    if(id === 'id')
    {
        const pw = document.getElementById("pw");
        pw.focus();
    }
    else
    {
        getLogin();
    }
}

const getInputValSet = () => {
    const id  = event.target.id;
    const val = event.target.value;

    obj.value[id] = val;
}

const getValueCheck = () => {
    if(obj.value.id === '')
    {
        alert('아이디를 입력해주세요.');
        return false;
    }

    if(obj.value.pw === '') 
    {
        alert('비밀번호를 입력해주세요.');
        return false;
    }

    return true;
}

const getLogin = async () => {
    if(!getValueCheck()) return;

    try
    {
        const res = await axios.post('https://po-data.plansys.kr/login', obj.value);

        if(res.status === 200)
        {
            store.dispatch('login/login', { jwt : res.data.data });
            router.push({ name : 'FactorApprList' });
        }
        else
        {
            alert('로그인 실패');
        }
    }
    catch(e)
    {
        console.log(e);
        alert('로그인 실패');
    }
}

</script>

<style scoped>

</style>